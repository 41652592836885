@font-face {
  font-family: "IntroRegular";
  src: local("IntroRegular"),
   url("./fonts/Intro/IntroRegular.ttf") format("truetype");
  font-weight: normal;
} 

@font-face {
  font-family: "IntroLight";
  src: local("IntroLight"),
   url("./fonts/Intro/IntroLight.ttf") format("truetype");
  font-weight: 400;
} 

@font-face {
  font-family: "IntroBold";
  src: local("IntroBold"),
   url("./fonts/Intro/IntroBold.ttf") format("truetype");
  font-weight: bold;
} 

body {
  margin: 0;
  font-family: 'IntroRegular', 'Segoe UI', 'Roboto', 'Oxygen', 
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-line-through {
	text-decoration: line-through;
}

.card .card-body .main .body p {
	margin: 0;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

#dialog, #dialog > div {
	display: block;
	min-width: 186px;
}

// #barcode-picker div:first-child {
// 	width: 100%;
//     height: 100%;
//     margin: auto;
//     position: relative;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     background: #000;
// }

.text-underline {
  text-decoration: underline;
}

.MuiInputLabel-formControl {
  font-family: 'IntroRegular', 'Segoe UI', 'Roboto', 'Oxygen', 
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}