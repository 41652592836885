$theme-colors: (
    "primary" : #E00F15,
    "secondary" : #252525,
    "success" : #198754,
    "info" : #0dcaf0,
    "warning" : #FAD500,
    "danger" : #E02B27,
    "light" : #E6E6E6,
    "dark" : #252525,
    "white" : #FFFFFF,
    "black" : #000
);
@import '~bootstrap/scss/bootstrap.scss';

$exclusiveColor: #2A93BD;
$newColor: #93B04B;
$limitedColor: #9B557F;
$specialOfferColor: #E33338;
$lightColor: #F8F9FA;
$darkColor: #1D1D1B;
$white: #FFFFFF;


// Flex-grid basic structure
#dialog .body .row>* {
    margin: 0;
    padding-right: 0;
    line-height: 1;
}

.current-price-legend {
    font-size: 12px;
    line-height: 12px;
}

.product-description {
    font-size: 14px;
}

#dialog .body .current-price-legend {
    width: 98%;
    margin-left: 3px;
}

#dialog .body .current-price sup {
    padding: 0;
    font-size: 27px;
    top: 2px;
}

#dialog .body .current-price-legend sup {
    top: 1px;
    font-size: 16px;
    padding: 0;
}

#dialog .body .current-price-legend p {
    font-size: 10px;
    padding: 0;
    margin-left: 2px;
}

#dialog .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;

    &.flex-column {
        flex-direction: column;
    }

    &.align-items-start {
        align-items: flex-start;
    }

    &.align-items-center {
        align-items: center;
    }

    &.align-items-end {
        align-items: flex-end;
    }

    &.justify-content-start {
        justify-content: flex-start;
    }

    &.justify-content-center {
        justify-content: center;
    }

    &.justify-content-end {
        justify-content: flex-end;
    }

    [class*="col"] {
        position: relative;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;

        &-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
    }
}

.pt-1 {
    padding-top: .25rem !important;
}

.pb-1 {
    padding-bottom: .25rem !important;
}

.pl-1 {
    padding-left: .25rem !important;
}

.pr-1 {
    padding-right: .25rem !important;
}

.card {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 20;
    background-color: unset;
    border: 0;

    .card-body {
        padding: 0;
        border-radius: 6px 6px 0 0;
        height: 100vh;
        width: 100%;
        background: var(--bs-white);
        overflow-y: auto;

        &.small {
            height: auto;
        }

        &.exclusive {
            border-color: $exclusiveColor;
        }

        &.new {
            border-color: $newColor;
        }

        &.limited {
            border-color: $limitedColor;
        }

        .main {
            color: var(--bs-dark);
            padding: .25rem;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 70px;

            &.main-white {
                background-color: white;
                color: var(--bs-dark);
            }

            .header {
                background-color: none;
                padding: .25rem 0;
                margin: 0;

                .product-title {
                    font-size: 16px;
                    font-weight: 400;
                }

                .button-close {
                    width: auto;
                    border: 1px solid #FFF;
                    user-select: none;
                    font-size: 16px;
                    line-height: 1.5;
                    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                    padding: 5px 9px;
                    margin: 0.18rem;
                    margin-right: .325rem;
                    font-weight: bold;
                    opacity: .9;
                    position: absolute;
                    top: 10px;
                    right: 20px;

                    &:hover {
                        background-color: $lightColor;
                    }
                }
            }

            .col.text-center {
                img {
                    height: auto;
                    max-width: 100%;
                }
            }

            .body {

                .before-price {
                    font-size: 14px;
                    font-weight: 400;
                    margin-right: 5px;
                }

                .current-text {
                    font-size: 20px;
                }

                .product-details-title {
                    line-height: 16px;

                    b {
                        font-size: 14px;
                    }
                }

                .price-comparation {
                    background-color: #FAD500;
                    padding: 5px 0;

                    p {
                        text-align: center;
                        font-size: 10px;
                        line-height: 13px;
                    }
                }

                .current-price {

                    p {
                        font-size: 22px;
                        font-weight: 700;
                        padding: 0;
                    }
                }
            }
        }
    }

    .card-footer {

        .footer-svg {
            width: 100%;
            height: auto;

            .footer-path {
                fill: $specialOfferColor;

                &.exclusive {
                    fill: $exclusiveColor;
                }

                &.new {
                    fill: $newColor;
                }

                &.limited {
                    fill: $limitedColor;
                }
            }

            .text-ptbr {
                font-weight: bold;
                font-size: 16px;
            }

            .text-us {
                font-weight: 500;
                font-size: 8px;
                font-style: italic;
            }
        }

    }
}

/* Datepicker */
.MuiFormControl-root {
    width: 100%;
}

.MuiPickersBasePicker-container .MuiTypography-root {
    text-transform: capitalize;
}


/* Invalid feedback (Form) */
.invalid-feedback .alert {
    margin-bottom: 0.4rem;
}

.checkout-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        height: auto;
        max-width: 100%;
        margin: 60px 0;
    }
}

/* Buttons */
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 22px;
    border-width: 2px;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 0;
    gap: 10px;

    &.btn-login {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 18px;
    }
}

.cart-box {
    display: flex;
    overflow-x: auto;
    padding-bottom: 10px;
}

.label-info {
    font-size: 10px;
    line-height: 10px;
}

@media (max-width: 767px) {
    .checkout-logo {
        img {
            width: 80%;
        }
    }

    .current-price-legend {
        font-size: 10px;
        line-height: 10px;
    }

    .product-description {
        font-size: 12px;
    }

    .card {
        .card-body {
            .main {
                .col.text-center {
                    img {
                        height: 125px;
                    }
                }
            }
        }
    }
}

.current-price sup {
    padding: 0;
    font-size: 20px;
    top: 0;
    left: 4px;
}

#e52s7YzcemE2_to {
    animation: "e52s7YzcemE2_to__to" 1800ms linear 1 normal forwards
}

@keyframes e52s7YzcemE2_to__to {
    0% {
        offset-distance: 0%
    }

    77.777778% {
        offset-distance: 100%
    }

    100% {
        offset-distance: 100%
    }
}

#e52s7YzcemE2_ts {
    animation: "e52s7YzcemE2_ts__ts" 1800ms linear 1 normal forwards
}

@keyframes e52s7YzcemE2_ts__ts {
    0% {
        transform: scale(1, 1)
    }

    77.777778% {
        transform: scale(0.62298, 0.622979)
    }

    100% {
        transform: scale(0.62298, 0.622979)
    }
}

#e52s7YzcemE9_to {
    animation: "e52s7YzcemE9_to__to" 1800ms linear 1 normal forwards
}

@keyframes e52s7YzcemE9_to__to {
    0% {
        transform: translate(111.5px, 72.557999px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
    }

    77.777778% {
        transform: translate(74px, 72.557999px)
    }

    100% {
        transform: translate(74px, 72.557999px)
    }
}

#e52s7YzcemE9_tr {
    animation: "e52s7YzcemE9_tr__tr" 1800ms linear 1 normal forwards
}

@keyframes e52s7YzcemE9_tr__tr {
    0% {
        transform: rotate(0deg)
    }

    38.888889% {
        transform: rotate(-15.000001deg)
    }

    77.777778% {
        transform: rotate(-0.000001deg)
    }

    100% {
        transform: rotate(-0.000001deg)
    }
}

#e52s7YzcemE12_tr {
    animation: "e52s7YzcemE12_tr__tr" 1800ms linear 1 normal forwards
}

@keyframes e52s7YzcemE12_tr__tr {
    0% {
        transform: translate(112.254px, 78.9227px) rotate(0deg)
    }

    38.888889% {
        transform: translate(112.254px, 78.9227px) rotate(44.999997deg)
    }

    77.777778% {
        transform: translate(112.254px, 78.9227px) rotate(-0.000003deg)
    }

    100% {
        transform: translate(112.254px, 78.9227px) rotate(-0.000003deg)
    }
}

#video {

    .scandit-logo {
        transform: scale(0.5) !important;
        bottom: 0 !important;
        right: 0 !important;
    }

    .scandit{
    
        .scandit-barcode-picker {
            height: 100vh !important;
        }
    }
}

.fullscreen-loading {
    text-align: center;
    color: #fff;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}