.space-center{
    width: 97%;
    text-align: center;
}

.top-corner {
    display: flex;
    width: 100%;
}

.top-left-corner {
    width: 20px;
    height: 20px;
    border: 2px solid #FFFFFF;
    border-bottom: none;
    border-right: none;
}

.top-right-corner {
    width: 20px;
    height: 20px;
    border: 2px solid #FFFFFF;
    border-left: none;
    border-bottom: none;
}

.bottom-corner {
    display: flex;
    width: 100%;
}

.bottom-left-corner {
    width: 20px;
    height: 20px;
    border: 2px solid #FFFFFF;
    border-top: none;
    border-right: none;
}

.bottom-right-corner {
    width: 20px;
    height: 20px;
    border: 2px solid #FFFFFF;
    border-top: none;
    border-left: none;
}

.reading-line {
    width: 70%;
    margin: 100px;
    height: 1px;
    background-color: red;
}

.marking-line {
    height: 5px;
    border: 2px solid darkred;
}
